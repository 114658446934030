import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './components/App.tsx';
import GlobalStyle from './style/global.ts';
import styled from 'styled-components';
import reportWebVitals from "./reportWebVitals";
import {BrowserRouter, Route, Routes} from "react-router-dom";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
    <Wrapper>
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<App/>}/>
            </Routes>
        </BrowserRouter>
        <GlobalStyle/>
    </Wrapper>
);

reportWebVitals();
