// import useLocalStorageState from 'use-local-storage-state';

export const DEFAULT_MEASUREMENT_DURATION = 120;
export const MIN_MEASUREMENT_DURATION = 20;
export const MAX_MEASUREMENT_DURATION = 180;
//
// export const useLicenseKey = useLocalStorageState('licenseKey', null);
// export const useProductId = useLocalStorageState('productId', null);
// export const useMeasurementDuration = useLocalStorageState(
//   'measurementDuration',
//   DEFAULT_MEASUREMENT_DURATION,
// );
