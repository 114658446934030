import React, {useCallback, useEffect, useState} from 'react';
import styled from 'styled-components';
import SettingsBars from './SettingsBars';
import {Flex} from './shared/Flex';
import {useCameras, useDisableZoom} from '../hooks';
import UAParser from 'ua-parser-js';
import {clearDataCache} from '../helpers/clearDataCache';
import BinahMonitor from "./BinahMonitor";
import {binahApiUrl, binahHeaders} from "../helpers/binahApi";
import {GoAlert} from "react-icons/go";
import { useSearchParams } from 'react-router-dom';

const ReachedUseGrid = styled(Flex)`
  height: 100vh;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 7px;
  p{
    padding-inline: 10px;
    font-size: 18px;
  }
  small{
    padding-inline: 10px;
    max-width: 500px;
    font-size: 14px;
    text-align: justify;
  }
`;

const Container = styled(Flex)<{ isSettingsOpen: boolean }>`
  height: 100%;
  width: 100%;
  position: relative;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  background-color: ${({isSettingsOpen}) =>
          isSettingsOpen && 'rgba(0, 0, 0, 0.5)'};
`;

const App = () => {
    clearDataCache();

    const cameras = useCameras();
    const [isSettingsOpen, setIsSettingsOpen] = useState(false);
    const [cameraId, setCameraId] = useState<string>();
    const [isLicenseValid, setIsLicenseValid] = useState(false);
    const [isMobile] = useState(
        UAParser(navigator.userAgent).device.type === 'mobile',
    );
    const [reachedUseLimit, setReachedUseLimit] = useState(false);
    const [noRefLimit, setNoRefLimit] = useState(false);
    const [searchParams] = useSearchParams();
    const [enabledVitalSigns, setEnabledVitalSigns] = useState([])
    const ref = searchParams.get("ref") ?? ''
    useDisableZoom();

    const onSettingsClickedHandler = useCallback((event) => {
        const settingsBars = document.getElementById('settingsBars');
        const isSettingsButtonClicked = event.target.id === 'settingsButton';

        const isInsideSettingsClicked =
            settingsBars.contains(event.target as Node) || isSettingsButtonClicked;

        if (!isInsideSettingsClicked) {
            setIsSettingsOpen(false);
        }
    }, []);

    useEffect(() => {
        const handleCounter = async () => {
            const makeRequest = async (visitCounter: number, id = null) => {
                await fetch(`${binahApiUrl}/counter/`, {
                    method: 'PUT',
                    headers: binahHeaders,
                    body: JSON.stringify({
                        data: {visitCounter, id}
                    }),
                });
                // localStorage.setItem('newUser', 'false');
            };
            const makeRequestReference = async (visitCounter: number, id = null) => {
                await fetch(`${binahApiUrl}/references/${id}`, {
                    method: 'PUT',
                    headers: binahHeaders,
                    body: JSON.stringify({
                        data: {visitCounter}
                    }),
                });
                localStorage.setItem('newUser', 'false');
            };
            const counterRequest = await fetch(`${binahApiUrl}/counter`, {
                method: 'GET',
                headers: binahHeaders,
            });
            const counterRefRequest = await fetch(`${binahApiUrl}/references?filters[slug][$eq]=${ref}&populate=*`, {
                method: 'GET',
                headers: binahHeaders,
            })
            const res = await counterRefRequest.json();
            const [refData = {}] = res?.data;
            const counter = await counterRequest.json() ?? {};
            if ("error" in counter) return;
            if ("attributes" in counter?.data) {
                const incrementIn = Number(counter.data.attributes.visitCounter) + 1;
                const id = counter.data?.id;
                if (!id) return;
                const newUser = localStorage.getItem('newUser');
                if (!newUser || newUser === 'true') {
                    makeRequest(incrementIn, id);
                }
                // if (counter.data.attributes.useCounter >= counter.data.attributes.useLimit) {
                //     setReachedUseLimit(true);

                // }
            }
            if("attributes" in refData){
                const {enabledVitalSigns = []} = refData?.attributes ?? {}
                setEnabledVitalSigns(enabledVitalSigns.map(({ vitalSign }) => vitalSign))
                const incrementIn = Number(refData.attributes.visitCounter) + 1;
                const id = refData?.id;
                if (!id) return;
                const newUser = localStorage.getItem('newUser');
                if (!newUser || newUser === 'true') {
                    makeRequestReference(incrementIn, id);
                }
                if (refData.attributes.useCounter >= refData.attributes.useLimit) {
                    setReachedUseLimit(true);

                }
            } else {
                setNoRefLimit(true);
            }
        };
        handleCounter();
        document.addEventListener('click', onSettingsClickedHandler);
        return () => {
            document.removeEventListener('click', onSettingsClickedHandler);
        };
    }, []);

    const updateLicenseStatus = useCallback((valid) => {
        setIsLicenseValid(valid);
    }, []);

    const toggleSettingsClick = useCallback(() => {
        setIsSettingsOpen(!isSettingsOpen);
    }, [isSettingsOpen]);

    const handleCloseSettings = useCallback(({cameraId}) => {
        setCameraId(cameraId);
        setIsSettingsOpen(false);
    }, []);

    useEffect(() => {
        if (!cameras?.length) return;
        setCameraId(cameras[0].deviceId);
    }, [cameras]);

    return (
        <Container isSettingsOpen={isSettingsOpen}>
            {
                reachedUseLimit ?
                    <ReachedUseGrid>
                        <GoAlert size={40} color={'red'}/>
                        <p>Limite de uso atingido</p>
                        <p><small>Caro usuário, o limite de uso da nossa plataforma foi atingido.</small></p>
                        <p><small>Entre em contato com o administrador para renovar seu ciclo de uso.</small></p>
                        <p><small>Agradecemos a sua compreensão.</small></p>
                        <p></p>
                    </ReachedUseGrid> :
                        noRefLimit ?
                            <ReachedUseGrid>
                                <GoAlert size={40} color={'red'}/>
                            <p>Acesso negado</p>
                            <p><small>Você precisa de um convite para continuar!</small></p>
                            </ReachedUseGrid> :
                            <BinahMonitor
                                showMonitor={!(isMobile && isSettingsOpen)}
                                cameraId={cameraId}
                                onLicenseStatus={updateLicenseStatus}
                                onSettingsClick={toggleSettingsClick}
                                isSettingsOpen={isSettingsOpen}
                                enabledVitalSigns={enabledVitalSigns}
                            />
            }
            <SettingsBars
                open={isSettingsOpen}
                onClose={handleCloseSettings}
                cameras={cameras}
                isLicenseValid={isLicenseValid}
            />
        </Container>
    );
};

export default App;
