function clearDataCache(): void {
  //persist new user
  const newUser = localStorage.getItem('newUser');
  // clear localStorage
  localStorage.clear();
  localStorage.setItem('newUser', newUser || 'true');

  // clear sessionStorage
  sessionStorage.clear();

  // clear cookies
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf('=');
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/';
  }

  // clear cache
  if (caches) {
    caches.keys().then(function (names) {
      for (const name of names) {
        caches.delete(name);
      }
    });
  }
}

export { clearDataCache };
