import React, {useCallback, useEffect, useMemo, useRef, useState,} from 'react';
import styled from 'styled-components';
import {HealthMonitorCodes, isIos, isMobile, isTablet, SessionState,} from '@binah/web-sdk';
import {useMediaPredicate} from 'react-media-hook';
import {useError, useMonitor, usePageVisibility, usePrevious, useWarning,} from '../hooks';
import Stats from './Stats';
import StartButton from './StartButton';
import {mirror} from '../style/mirror';
import {Flex} from './shared/Flex';
import Timer from './Timer';
import media from '../style/media';
import InfoBar from './InfoBar';
import {ErrorAlert, InfoAlert, WarningAlert} from './alert';
import Loader from './Loader';
import {VideoReadyState} from '../types';
import TopBar from './TopBar';
import Mask from '../assets/mask.svg';
import {useSearchParams} from 'react-router-dom';
import BinahResults from './BinahResults';
import {binahApiUrl, binahHeaders} from '../helpers/binahApi';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const ResultWrapper = styled(Flex)<{ isSettingsOpen: boolean }>`
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex: 1;
`;

const MonitorWrapper = styled(Flex)<{ isSettingsOpen: boolean }>`
  flex-direction: column;
  width: 100%;
  justify-content: start;
  align-items: center;
  flex: 1;
  z-index: ${({isSettingsOpen}) => isSettingsOpen && '-1'};
  ${media.tablet`
    width: fit-content;
    justify-content: center;
  `}
`;

const MeasurementContentWrapper = styled(Flex)<{ isMobile: boolean }>`
  width: auto;
  height: ${({isMobile}) => isMobile && '100%'};
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  ${media.mobile`
    margin: 40px 0 60px 0;
  `}
`;

const VideoAndStatsWrapper = styled(Flex)<{ isMobile: boolean }>`
  position: relative;
  justify-content: center;
  width: 100%;
  height: ${({isMobile}) => isMobile && '100%'};
  ${media.tablet`
    width: 812px;
    height: 609px;
  `} ${media.wide`
    width: 1016px;
    height: 762px;
  `};
`;

const VideoWrapper = styled.div`
  width: 100%;
  height: 100%;
  z-index: -1;
`;

const Img = styled.img<{ isDesktop: boolean }>`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  object-fit: ${({isDesktop}) => (isDesktop ? 'contain' : 'cover')};
  border-radius: 40px;
`;

const Video = styled.video<{ isMobile: boolean }>`
  width: 100%;
  height: 100%;
  border-radius: 40px;
  object-fit: ${() => (isIos() ? 'unset' : isMobile() ? 'cover' : 'contain')};
  ${mirror}
`;

const ButtonWrapper = styled(Flex)`
  flex: 2;
  z-index: 3;
  width: 100%;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  margin-top: -30px;
  ${media.mobile`
    margin-top: 50px;
  `}
  ${media.tablet`
  padding: 0;
  height: auto;
  width: auto;
  position: absolute;
  right: 0;
  bottom: 42%;
  margin-right: 60px;
`}
`;

const ButtomTimerWrapper = styled(Flex)`
  display: none;
  ${media.tablet`
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    height: 30px;
    display: flex;
  `}
`;

const InfoBarWrapper = styled.div`
  height: 25px;
  width: 100%;
  display: flex;
  align-items: flex-end;
  ${media.mobile`
    flex: 0.45;
  `}
`;

const BinahMonitor = ({
                          showMonitor,
                          cameraId,
                          onLicenseStatus,
                          onSettingsClick,
                          isSettingsOpen,
                          enabledVitalSigns
                      }) => {
    const showSwal1 = () => {
        withReactContent(Swal).fire({
            icon: "warning",
            title: "Atenção",
            html:   `<small>
                        Os indicadores não devem ser usados como substitutos de uma visita médica e nem podem ser considerados de uso médico.
                        São indicadores meramente de referência. Em caso de dúvida procure seu médico.
                        <br/><br/><b>IMPORTANTE: Os dados serão coletados e utilizados no momento do escaneamento, após isso nenhum dado será armazenado.</b>
                    </small>`,
            confirmButtonText: "Aceito",
            allowOutsideClick: false,
            preConfirm: () => showSwal2()
        })
    }
    const showSwal2 = () => {
        withReactContent(Swal).fire({
            icon: "info",
            title: "Atenção",
            html:   `<small>
                        O teste terá duração de apenas 1 minuto. Antes de iniciar o escaneamento feche todas as abas e aplicativos que possam consumir recursos do
                        aparelho, esteja em um ambiente bem iluminado, mantendo a câmera estável e apontando para o rosto
                        durante todo o tempo.
                    </small>`,
            confirmButtonText: "Ok",
            allowOutsideClick: true,
        })
    }
    const [searchParams] = useSearchParams();
    const redirectParams: any = useRef();
    const ref = searchParams.get('ref');
    useEffect(() => {
        showSwal1();
        const params = searchParams.entries();
        const values = {};
        // @ts-ignore
        for (const entry of params) {
            values[entry[0]] = entry[1];
        }
        redirectParams.current = values;
    }, []);
    const video = useRef<HTMLVideoElement>(null);
    const [isMeasurementEnabled, setIsMeasurementEnabled] =
        useState<boolean>(false);
    const [startMeasuring, setStartMeasuring] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [loadingTimeoutPromise, setLoadingTimeoutPromise] = useState<number>();
    const isPageVisible = usePageVisibility();
    const isMediaTablet = useMediaPredicate('(min-width: 1000px)');

    const processingTime = process.env.REACT_APP_DURATION ?? '60';
    const licenseKey = process.env.REACT_APP_LICENSE ?? '';
    const {
        sessionState,
        vitalSigns,
        offlineMeasurements,
        error,
        warning,
        info,
    } = useMonitor(
        video,
        cameraId,
        Number(processingTime),
        licenseKey,
        null,
        startMeasuring,
        enabledVitalSigns
    );
    const buildQueryString = (url: string, args: any) => {
        const searchURL = new URL(url);
        for (const key of Object.keys(args)) {
            if (key === 'fullPath') continue;
            searchURL.searchParams.set(key, args[key]);
        }
        return searchURL;
    };
    const [measuringEnded, setMeasuringEnded] = useState(false);
    useEffect(() => {
        const updateUseCounter = async () => {
            const counterRequest = await fetch(`${binahApiUrl}/counter`, {
                method: 'GET',
                headers: binahHeaders
            });
            const counter = await counterRequest.json();
            if ('error' in counter) return;
            if ('data' in counter) {
                const useCounter = Number(counter.data?.attributes?.useCounter) + 1;
                const id = counter.data?.id;
                if (!id) return;
                await fetch(`${binahApiUrl}/counter/`, {
                    method: 'PUT',
                    headers: binahHeaders,
                    body: JSON.stringify({
                        data: {useCounter, id}
                    }),
                });
            }
        };
        const updateRefUseCounter = async () => {
            const counterRefRequest = await fetch(`${binahApiUrl}/references?filters[slug][$eq]=${ref}`, {
                method: 'GET',
                headers: binahHeaders,
            });
            const {data: [counter = {}]} = await counterRefRequest.json();
            if ('error' in counter) return;
            if ('attributes' in counter) {
                const useCounter = Number(counter.attributes.useCounter) + 1;
                const id = counter?.id;
                if (!id) return;
                await fetch(`${binahApiUrl}/references/${id}`, {
                    method: 'PUT',
                    headers: binahHeaders,
                    body: JSON.stringify({
                        data: {useCounter}
                    }),
                });
            }
        };
        if (sessionState === SessionState.ACTIVE && startMeasuring) {
            setMeasuringEnded(true);
            updateUseCounter();
            updateRefUseCounter();
            if (!redirectParams.current || Object.keys(redirectParams.current).length === 0) return;
            // openGID();
        } else {
            setMeasuringEnded(false);
        }
    }, [sessionState]);
    const openGID = () => {
        const goTo = `${process.env.REACT_APP_REDIRECT_URI}${redirectParams.current['fullPath']}`;
        const targetVitalSigns = ['heartRate', 'breathingRate', 'bloodPressure'];
        const vitalSignsToSend = {};
        for (const vitalSign of targetVitalSigns) {
            if (vitalSigns[vitalSign]) {
                vitalSignsToSend[vitalSign] = vitalSigns[vitalSign].value ?? {};
            }
        }
        const queryString = buildQueryString(goTo, {
            ...redirectParams.current,
            'vitalSigns': JSON.stringify(vitalSignsToSend),
        });
        // go to url with target="_blank"
        window.open(queryString.toString(), '_blank');
    };
    const prevSessionState = usePrevious(sessionState);
    const errorMessage = useError(error);
    const warningMessage = useWarning(warning);

    const isMeasuring = useCallback(
        () => sessionState === SessionState.MEASURING,
        [sessionState]
    );

    const isVideoReady = useCallback(
        () => video.current?.readyState === VideoReadyState.HAVE_ENOUGH_DATA,
        []
    );

    const handleButtonClick = useCallback(() => {
        // Aqui é onde o botão de iniciar a medição é clicado
        setIsLoading(true);
        setMeasuringEnded(false);
        if (sessionState === SessionState.ACTIVE) {
            setStartMeasuring(true);
            setLoadingTimeoutPromise(
                window.setTimeout(() => setIsLoading(true), Number(processingTime) * 1000)
            );
        } else if (isMeasuring()) {
            clearTimeout(loadingTimeoutPromise);
            setStartMeasuring(false);
        }
    }, [sessionState, setIsLoading, processingTime, isMeasuring]);

    useEffect(() => {
        if (isMeasuring()) {
            setIsLoading(false);
            if (errorMessage) {
                setIsMeasurementEnabled(false);
            } else {
                setIsMeasurementEnabled(true);
            }
            !isPageVisible && setStartMeasuring(false);
        } else if (
            (sessionState === SessionState.ACTIVE ||
                sessionState === SessionState.TERMINATED) &&
            errorMessage
        ) {
            setIsMeasurementEnabled(false);
        }
        if (
            sessionState === SessionState.ACTIVE &&
            prevSessionState !== sessionState
        ) {
            setStartMeasuring(false);
            setIsLoading(false);
        }
    }, [errorMessage, sessionState, isPageVisible]);

    useEffect(() => {
        onLicenseStatus(!(error?.code in HealthMonitorCodes));
    }, [error]);

    const mobile = useMemo(() => isMobile(), []);
    const desktop = useMemo(() => !isTablet() && !isMobile(), []);

    if (!showMonitor) {
        return null;
    }

    return (
        <>
            <TopBar onSettingsClick={onSettingsClick} isMeasuring={isMeasuring()}/>
            <div style={{display: measuringEnded ? 'none' : 'block'}}>
                <MonitorWrapper isSettingsOpen={isSettingsOpen}>
                    <MeasurementContentWrapper isMobile={mobile}>
                        <InfoBarWrapper>
                            <InfoBar
                                showTimer={isMeasurementEnabled && !isMediaTablet}
                                isMeasuring={isMeasuring()}
                                durationSeconds={Number(processingTime)}
                                offlineMeasurements={offlineMeasurements}
                            />
                        </InfoBarWrapper>
                        <VideoAndStatsWrapper isMobile={mobile}>
                            <VideoWrapper>
                                <Img src={Mask} isDesktop={desktop}/>
                                <Video
                                    ref={video}
                                    id="video"
                                    muted={true}
                                    playsInline={true}
                                    isMobile={isMobile()}
                                />
                            </VideoWrapper>
                            {(isMeasuring()
                                    ? !errorMessage && !warningMessage
                                    : !errorMessage) &&
                                isMeasurementEnabled && <Stats vitalSigns={vitalSigns}/>}
                            <ErrorAlert message={errorMessage}/>
                            {isMeasuring() && <WarningAlert message={warningMessage}/>}
                            {isMeasuring() && <InfoAlert message={info.message}/>}
                            {!isVideoReady() && licenseKey && <Loader/>}
                        </VideoAndStatsWrapper>
                        <ButtomTimerWrapper>
                            {isMeasurementEnabled && (
                                <Timer started={isMeasuring()} durationSeconds={processingTime}/>
                            )}
                        </ButtomTimerWrapper>
                        <ButtonWrapper>
                            <StartButton
                                isLoading={isLoading}
                                isMeasuring={isMeasuring()}
                                onClick={handleButtonClick}
                            />
                        </ButtonWrapper>
                    </MeasurementContentWrapper>
                </MonitorWrapper>
            </div>
            {
                measuringEnded && (
                    <ResultWrapper isSettingsOpen>
                        <BinahResults callback={handleButtonClick} vitalSigns={vitalSigns}/>
                    </ResultWrapper>
                )
            }
        </>
    );
};

export default BinahMonitor;
