import {VitalSigns} from '../../public/common/types';
import styled from 'styled-components';
import {Flex} from './shared/Flex';
import {AiOutlineHeart} from 'react-icons/ai';
import {BsLungs} from 'react-icons/bs';
import {MdInfoOutline, MdOutlineBloodtype} from 'react-icons/md';
import React, {ReactNode, useMemo} from 'react';
import {FiAlertTriangle} from 'react-icons/fi';
import {formattedSignsVitals} from '../helpers/FormattedSignsVitals';

interface PageProps {
    vitalSigns: VitalSigns;
    callback: () => void;
}

const Container = styled(Flex)`
  width: 100%;
  margin-inline: 10px;
  padding: 10px;
  flex-direction: column;
  align-items: center;
  overflow: auto;
`;

const ContainerHeader = styled(Flex)`
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1440px;
  gap: 25px;
`;
const TrDescription = styled.div`
  background-color: #fff;
  text-align: left !important;
  width: 100%;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.5;
  //font-style: italic;
  margin: -5px 0 1px 0;
  padding: 0 0 10px 20px;
  display: flex;
  align-items: center;
  gap: 5px;
`;
const ContainerTable = styled(Flex)`
  width: 100%;
  max-width: 1130px;
  margin-top: 20px;
  overflow: auto;
    flex-direction: column;

  div {
    text-align: center;
  }

  table {
    width: 100% !important;
    border-collapse: collapse;
    display: flex;
    flex-flow: column;
    height: 400px;

    tbody {
      flex: 1 1 auto;
      display: block;
      overflow-y: auto;
      overflow-x: hidden;
    }

    td, th {
      padding: 20px;
    }

    tr {
      width: 100%;
      display: table;
      table-layout: fixed;
      border-bottom: 1px solid #f1f4f9;
      background-color: white;

    }

    tr:last-child {
      border-bottom: none;
    }

    tr:hover {
      background-color: #f1f4f9;
    }

    th {
      padding: 20px;
      text-align: left;
      background-color: #CC3366;
      color: white;
    }

    th:first-child {
      border-top-left-radius: 10px;
    }

    th:last-child {
      border-top-right-radius: 10px;
    }

    tr:last-child td:first-child {
      border-bottom-left-radius: 10px;
    }

    tr:last-child td:last-child {
      border-bottom-right-radius: 10px;
    }
  }
`;
const Card = styled(Flex)`
  border-radius: 100px;
  width: 100%;
  padding: 20px 25px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 4px 7px rgba(0, 0, 0, 0.2);
  background: #fff;

  div {
    div {
      gap: 3px;
      display: flex;
      align-items: center;
    }

    h2 {
      margin: 0;
    }
  }

  @media (min-width: 800px) {
    max-width: 300px;
  }
`;

const Button = styled('button')`
  margin-top: 20px;
  padding: 10px;
  background: #CC3366;
  color: white;
  border: none;
  border-radius: 10px;
  cursor: pointer;
`;
const ButtonWrapper = styled(Flex)`
  gap: 10px;
`;
const InfoCard = ({title, value, unit, icon}: {
    title: string,
    value: number | string,
    unit?: string,
    icon: ReactNode
}) => {
    return (
        <Card>
            <div>
                <div>
                    <h2>{value}</h2>
                    {unit && <small>{unit}</small>}
                </div>
                <h4>{title}</h4>
            </div>
            {icon}
        </Card>
    );
};

const DetailedSignGrid = styled(Flex)`
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  border-bottom: 1px solid #f1f4f9;
  width: 100%;
  border-radius: 10px;
  padding: 20px 25px;
  justify-content: space-between;
  background: #fff;
  margin-bottom: 20px;
  box-sizing: border-box;

  h1 {
    margin: 0;
    font-size: 18px;
  }

  p {
    margin: 0;
    font-size: 16px;
  }

  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    text-align: left !important;
  }

  &:last-child {
    border-bottom: none;
  }
`;
const BinahResults = ({vitalSigns, callback}: PageProps) => {
    const [showDetails, setShowDetails] = React.useState(false);
    const {heartRate, breathingRate, bloodPressure} = vitalSigns ?? {};
    const otherVitalSigns = {...vitalSigns};
    // otherVitalSigns.heartRate = 2;
    // otherVitalSigns.breathingRate = 2;
    // otherVitalSigns.bloodPressure = 2;
    // otherVitalSigns.stressLevel = 2;
    const formattedSigns = useMemo(() => {
        if (!otherVitalSigns) return formattedSignsVitals;
        return formattedSignsVitals.map((sign) => {
            if (otherVitalSigns[sign.key]) {
                if(sign.key === 'bloodPressure') {
                    return {...sign, value: otherVitalSigns[sign.key]?.value?.systolic && otherVitalSigns[sign.key]?.value?.diastolic ? `${otherVitalSigns[sign.key]?.value?.systolic}/${otherVitalSigns[sign.key]?.value?.diastolic}` : 'Desconhecido'};
                }else if (typeof otherVitalSigns[sign.key] === 'object') {
                    return {...sign, value: otherVitalSigns[sign.key]?.value ?? 'Desconhecido'};
                } else {
                    return {...sign, value: otherVitalSigns[sign.key] ?? 'Desconhecido'};
                }
            } else {
                return sign;
            }
        }).filter((sign) => sign.value !== 'Desconhecido');
    }, [otherVitalSigns]);
    return (
        <Container>
            <ContainerHeader>
                <InfoCard title={'Batimento Cardíaco'} value={heartRate.value ?? 0} unit={'bpm'}
                          icon={<AiOutlineHeart size={'40px'} color={'red'}/>}/>
                <InfoCard title={'Frequência Respiratória'} value={breathingRate.value ?? 0} unit={'brpm'}
                          icon={<BsLungs size={'40px'} color={'green'}/>}/>
                <InfoCard title={'Pressão Sanguínea'}
                          value={`${bloodPressure.value?.systolic ?? 0}/${bloodPressure.value?.diastolic ?? 0}`}
                          unit={'mmHg'}
                          icon={<MdOutlineBloodtype size={'40px'} color={'red'}/>}/>
                {/*<InfoCard title={'Estresse'} value={'normal'}*/}
                {/*          icon={<FaRegFaceAngry size={"40px"} color={"#b2b20a"}/>}/>*/}
                {/*<InfoCard title={'Var. Frequência Cardíaca'} value={42} unit={'ms'}*/}
                {/*          icon={<AiOutlineHeart size={"40px"} color={"red"}/>}/>*/}
            </ContainerHeader>
            <ContainerTable>
                {Object.entries(otherVitalSigns).length > 0 ?
                    showDetails
                        ? <>
                            {
                                formattedSigns.map((sign) => (
                                    <DetailedSignGrid key={sign.key}>
                                        <h1>{sign.name}</h1>
                                        <p>Valor: {sign.value}
                                            {sign.value !== 'Desconhecido' && sign.unit && <span style={{fontSize:'13px'}}> {sign.unit}</span>}
                                        </p>
                                        <p>
                                            {sign.description?.split('\n')?.map((d) => <p>{d}</p>) ?? 'Não informado'}
                                        </p>
                                    </DetailedSignGrid>
                                ))
                            }
                            </> : <table>
                            <thead>
                            <tr>
                                <th>Medição</th>
                                <th>Valor</th>
                            </tr>
                            </thead>
                            <tbody>
                            {formattedSigns.map((sign) => (
                                <>
                                    <tr key={sign.key}>
                                        <td style={{minWidth: '200px'}}>{sign.name}</td>
                                        <td>{sign.value} {sign.value !== 'Desconhecido' && sign.unit &&
                                            <span>{sign.unit}</span>}</td>
                                    </tr>
                                    {showDetails && <TrDescription>
                                        <MdInfoOutline size={'15px'}/>
                                        <span>{sign.description ?? 'Não informado'}</span>
                                    </TrDescription>}
                                </>
                            ))}
                            </tbody>
                        </table>
                    :
                    <div>
                        <FiAlertTriangle color={'#b2b20a'} size={'30px'}/>
                        <h4>Não foram encontradas outras medições</h4>
                    </div>}
            </ContainerTable>

            <ButtonWrapper>
                <Button onClick={() => callback()}>Refazer Medições</Button>
                <Button onClick={() => setShowDetails(!showDetails)}>{
                    showDetails ? 'Ocultar Detalhes' : 'Mostrar Detalhes'
                }</Button>
            </ButtonWrapper>
        </Container>
    );
};

export default BinahResults;